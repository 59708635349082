const TopBanner: React.FC = () => {
  return (
    <div className="bg-primary text-xs md:text-base h-14 w-full flex items-center justify-center">
      <p className="text-white semibold">
        Unlock the secrets within your DNA and discover a customized health plan
        designed to enhance your well-being.
      </p>
    </div>
  );
};

export default TopBanner;
